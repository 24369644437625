
  import { Component, Prop } from 'vue-property-decorator'
  import { LaborData } from '@/entities/persons'
  import { Expandable } from '@/components/toolkit/details/row/custom/Expandable'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'
  import LaborDataFiles from '@/components/forms/laborData/laborDataFiles.vue'

@Component({
  components: { LaborDataFiles },
})
  export default class ExpandableLaborData extends Expandable {
  @Prop({ type: String, default: '' }) path: string

  get basicData (): Array<{
    activityType: string,
    laborData: Array<{ type: string, number: string, holder: string }>
  }> {
    const { value, fields } = this

    if (!value) return []

    return value?.laborData?.map((val: LaborData) => {
      const data = []

      fields.forEach(({ label, path, transform }) => {
        if (transform) {
          data.push({
            fName: label,
            fValue: this.transformData(transform, getObjectValueByPath(val, path)),
          })
        } else {
          data.push({
            fName: label,
            fValue: getObjectValueByPath(val, path),
          })
        }
      })

      return {
        activityType: val.activityType.description,
        laborData: data,
        id: val.id,
      }
    }) || []
  }
  }
